import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './LoadingOverlay.css';

const getPaddingOffset = () => {
  if (window.matchMedia('(max-width: 1025px)').matches) return 0; // Very low-res viewport
  return 55; // Default padding
};

const LoadingOverlay = ({ relative }) => {
  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    const mainContent = document.getElementById('mainContent');

    if (!mainContent) return;
    const header = mainContent.querySelector('h1'); // Finds h1 inside mainContent

    const updateDimensions = () => {
      const padding = getPaddingOffset();
      const headerHeight = header ? header.offsetHeight + 145 : 0; // Get header height if it exists, adding aprox all other paddings
      setDimensions({
        width: `${mainContent.offsetWidth - padding * 2}px`, // Get dynamic width
        height: `${mainContent.offsetHeight - headerHeight}px`, // Get dynamic height
      });
    };

    updateDimensions(); // Set initial size

    const observer = new ResizeObserver(updateDimensions);
    observer.observe(mainContent);

    return () => observer.disconnect();
  }, []);

  return (
    <div className={!relative ? styles.wrapper : undefined}>
      <div
        className={styles.loading}
        style={{ width: dimensions.width, height: dimensions.height }}
      >
        <div className={styles.spinner}>
          <div className={styles.bounce1} />
          <div className={styles.bounce2} />
          <div className={styles.bounce3} />
        </div>
      </div>
    </div>
  );
};

LoadingOverlay.propTypes = {
  relative: PropTypes.bool,
};

export default LoadingOverlay;
