import React from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  DashboardBadge,
  Panel,
  Button,
  Paragraph,
  Heading,
  TableView,
  KpiPanels,
  LoadingOverlay,
} from 'components';
import { localize } from 'utils/utils';

import mapData, { apiFields, getHeaders } from 'utils/mapApiData';
import polyglot from 'i18n';
import styles from './DashboardTemplate.css';

const DashboardTemplate = ({
  eiendommer,
  grunneiendommer,
  bygninger,
  handleDownload,
  lokaleStats,
  data,
  loadingTable,
  changePage,
  bodyClick,
  sortClick,
  table,
  isGlobal,
  isDep,
  isPublic,
  currentVirksomhet,
  currentLevel,
  drilldownFunc,
  kpiTable,
  kpiTableSort,
  changeKpiTablePage,
  powerBIprops,
  ...props
}) => {
  const firstHeader = () => {
    if (currentLevel === 'STATEN') return 'Departement';
    if (currentLevel === 'DEPARTEMENT') return 'Virksomhet';
    return 'Lokale';
  };
  const { kpi, tables } = lokaleStats;
  const tableGenerator = (tableType, val1, val2, val3?) =>
    tables[tableType].length > 0 && (
      <TableView
        responsive
        drilldown
        sortClick={kpiTableSort}
        hits={tables[tableType].length}
        bodyClick={i => drilldownFunc(tableType, i)}
        changePage={changeKpiTablePage}
        className={styles.panelContent}
        table={kpiTable}
        dataRows={tables[tableType].map(it => it.table)}
        hideOptions
        headerRow={[
          firstHeader(),
          polyglot.t(`leideLokaler.${val1}`),
          polyglot.t(`leideLokaler.${val2}`),
          val3 && polyglot.t(`leideLokaler.${val3}`),
        ]}
      />
    );

  const areaPanel = tableGenerator(
    'officeTable',
    'kontoransatte',
    'totalKontorareal',
    'arealPerAnsatt',
  );
  const nonOfficeAreaPanel = tableGenerator(
    'nonOfficeTable',
    'ikkekontoransatte',
    'totalIkkeKontorareal',
    'arbeidsrelatertarealPerAnsatt',
  );
  const costPanel = tableGenerator(
    'costTable',
    'totalkostnad',
    'kostnaderPerKvm',
  );
  const humanPanel = tableGenerator(
    'employeeTable',
    'ansatte',
    'aarsverk',
    'kostnaderPerAarsverk',
  );

  const tableColumns = apiFields[
    isGlobal ? 'rapporter' : 'leideLokaler'
  ].searchTable.map(it => it.field);

  const isMobile = () => screen.width < 800;

  return (
    <div className={styles.wrapper}>
      <Heading level={0}>
        {`${polyglot.t('dashboard.header')} ${currentVirksomhet}`}
      </Heading>
      <div className={styles.buttonWrapper}>
        <Button kind="primary" to="/d/lediglokale" id={styles.button}>
          {polyglot.t('ledigeLokaler.header')}
        </Button>
      </div>

      {isMobile() && <Alert>{polyglot.t('dashboard.mobile')}</Alert>}

      {!kpi && <LoadingOverlay relative />}

      {kpi && (
        <KpiPanels
          {...{
            areaPanel,
            nonOfficeAreaPanel,
            costPanel,
            humanPanel,
            kpi,
            currentVirksomhet,
            ...props,
          }}
          totalKontorareal={`${localize(kpi.totalKontorareal.value)} m\u00B2`}
          kvmPrAnsatt={`${localize(kpi.arealPerAnsatt.value)} m\u00B2`}
          totalIkkeKontorareal={`${localize(
            kpi.totalIkkeKontorareal.value,
          )} m\u00B2`}
          arbeidsrelatertarealPerAnsatt={`${localize(
            kpi.arbeidsrelatertarealPerAnsatt.value,
          )} m\u00B2`}
          totalKostnad={localize(kpi.totalkostnad.value)}
          kostPrKvm={localize(kpi.kostnaderPerKvm.value)}
          aarsverk={localize(kpi.aarsverk.value)}
          kostPrAarsverk={localize(kpi.kostnaderPerAarsverk.value)}
          type="dashboard"
          isPublic={isPublic}
          powerBIprops={powerBIprops}
          currentLevel={currentLevel}
        />
      )}

      <Panel className={styles.reports}>
        <div className={styles.spaceBetween}>
          <Heading level={1}>
            {isGlobal
              ? polyglot.t('dashboard.rapportListe')
              : polyglot.t('dashboard.lokaleListe')}
          </Heading>
          {!isGlobal && (
            <Button kind="primary" to="/d/lokale/nyttlokale">
              {polyglot.t('leideLokaler.addLokale')}
            </Button>
          )}
        </div>
        <TableView
          hits={data.hits}
          drilldown
          headerRow={getHeaders(
            tableColumns,
            isGlobal ? 'rapporter' : 'leideLokaler',
          )}
          dataRows={
            data.result &&
            data.result.map(rowItem => [...mapData(rowItem, tableColumns)])
          }
          dataType="leideLokaler"
          loading={loadingTable}
          caption={polyglot.t('leideLokaler.caption')}
          hideOptions
          {...{ changePage, sortClick, bodyClick, table }}
        />
      </Panel>
    </div>
  );
};

DashboardTemplate.propTypes = {
  heading: PropTypes.string,
  eiendommer: PropTypes.number,
  grunneiendommer: PropTypes.number,
  bygninger: PropTypes.number,
  handleDownload: PropTypes.func,
  stats: PropTypes.arrayOf(PropTypes.object),
  kpi: PropTypes.object,
  data: PropTypes.shape({
    result: PropTypes.array,
    hits: PropTypes.number,
  }),
  loadingTable: PropTypes.bool,
  changePage: PropTypes.func,
  bodyClick: PropTypes.func,
  sortClick: PropTypes.func,
  table: PropTypes.object,
  isGlobal: PropTypes.bool,
  isDep: PropTypes.bool,
  isPublic: PropTypes.bool,
  currentLevel: PropTypes.string,
  drilldownFunc: PropTypes.func,
  brukernavn: PropTypes.string,
  currentVirksomhet: PropTypes.string,
  kpiTableMeta: PropTypes.shape({
    shown: PropTypes.number,
    page: PropTypes.number,
    sortCol: PropTypes.number,
  }),
  changeKpiTablePage: PropTypes.func,
};

DashboardTemplate.defaultProps = {
  data: {
    result: [],
    hits: 0,
  },
};

export default DashboardTemplate;
